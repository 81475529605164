import React from 'react';
import '../css/App.css';
import imageapp from '../svg/mobile.svg'
function App() {
  return (
    <div className="App">
      <img className="PadingTop" alt="desenvolvimento de aplicativo" width="200" src={imageapp} /><br />
      <h1>Trabalhamos e desenvolvemos tecnologias <br /> para agências de publicidade e marketing</h1>
      <p className="ByText">
        Estamos criando em um novo site, para maiores informações:
      </p>
      <p className="ByText">
      matheus@lobofrontal.com.br
      </p>
    </div>
  );
}

export default App;
